import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { env } from "$env/dynamic/public";

Sentry.init({
  dsn: env["PUBLIC_SENTRY_DSN"],

  tracesSampleRate: 0.01,

  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 0.1,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
